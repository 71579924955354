// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--eCRwg";
export var back = "style-module--back--LDo0c";
export var borderBtnOrange = "style-module--borderBtnOrange--YS255";
export var chromeframe = "style-module--chromeframe--386rc";
export var createAccount = "style-module--createAccount--7pTAE";
export var disabled = "style-module--disabled--W2KI1";
export var errorText = "style-module--errorText--5PNco";
export var ghost = "style-module--ghost--O6our";
export var loginBtn = "style-module--loginBtn--h4thY";
export var loginFormBtn = "style-module--loginFormBtn--xZzjP";
export var more = "style-module--more--NBx1G";
export var moreCircle = "style-module--moreCircle--CwIsg";
export var overlay = "style-module--overlay--0Fhvm";
export var signupFormBtn = "style-module--signupFormBtn--t8u5-";
export var textDefault = "style-module--text-default--XUR2f";
export var textInput = "style-module--text-input--KF4Kc";
export var videoJs = "style-module--video-js--0Xxap";
export var vjsBigPlayButton = "style-module--vjs-big-play-button---Jlhu";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--5gndN";
export var vjsPoster = "style-module--vjs-poster--82t1f";