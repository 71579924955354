import React, { FC } from 'react';
import { Link } from 'gatsby';
import img from 'assets/images/404.png';
import * as styles from './style.module.scss';
import { Button } from '../../atoms/Button';
import { Logo } from '../../atoms/Logo';

type Props = {
	path?: string;
};

export const Page404: FC<Props> = () => {
	return (
		<div className={styles.container}>
			<Link to="/" className={styles.logo}>
				<Logo variant="normal" />
			</Link>
			<div className={styles.content}>
				<img src={img} alt="404" />
				<p>Remember, what might seem like a huge mistake to you will go unnoticed by most of the audience.</p>
				<Link to="/">
					<Button className="loginBtn">Back to homepage</Button>
				</Link>
			</div>
		</div>
	);
};
