// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--eO-kA";
export var chromeframe = "style-module--chromeframe--Cl+s9";
export var container = "style-module--container--1vuaW";
export var content = "style-module--content--3EeCP";
export var errorText = "style-module--errorText--7yFmL";
export var logo = "style-module--logo--5H24H";
export var overlay = "style-module--overlay--nqtov";
export var textDefault = "style-module--text-default--UuR7K";
export var textInput = "style-module--text-input--ZxPut";
export var videoJs = "style-module--video-js--e3MQ7";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--QPyhx";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--maYVt";
export var vjsPoster = "style-module--vjs-poster--SybJu";