import React from 'react';
import logo from 'assets/icons/logo.svg';
import logoLight from 'assets/icons/logoLight.svg';
import * as styles from './style.module.scss';

type Props = {
	variant?: 'normal' | 'light';
	width?: number;
	height?: number;
};

export const Logo: React.FC<Props> = ({ variant, width, height }) => {
	return (
		<img
			className={styles.container}
			width={width || 96}
			height={height || 32}
			src={variant === 'light' ? logoLight : logo}
			alt="LOGO"
		/>
	);
};

Logo.defaultProps = {
	variant: 'normal',
};
