// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--r7u93";
export var chromeframe = "style-module--chromeframe--sTpES";
export var container = "style-module--container--KKJw2";
export var errorText = "style-module--errorText--EUn9b";
export var overlay = "style-module--overlay--mP4BN";
export var textDefault = "style-module--text-default--HDJ9G";
export var textInput = "style-module--text-input--G+9Yi";
export var videoJs = "style-module--video-js--DZmbf";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--K+Bhr";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--mxWa8";
export var vjsPoster = "style-module--vjs-poster--o4Dap";