import React, { PropsWithChildren } from 'react';
import * as styles from './style.module.scss';

type Props = {
	children: PropsWithChildren<any> | string;
	className?: string;
	disabled?: boolean;
	onClick?: () => void;
	type?: 'button' | 'submit' | 'reset' | undefined;
};

export const Button: React.FC<Props> = ({ children, className = '', type, disabled, onClick }) => {
	return (
		<button type={type} className={styles[disabled ? 'disabled' : className]} onClick={onClick}>
			{children}
		</button>
	);
};
